import { useState } from "react";
import { useCookies } from "react-cookie";
import { Button, Slider, Container, Grid, Typography, Icon } from '@material-ui/core'
import { useEffect } from "react";

function Abstimmung(props: any) {

  function formatDate(date: Date): string {
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
    return date.getFullYear() + "-" + month + "-" + date.getDate()
  }

  function formatDateTechnical(date: Date): string {
    let day: string = date.getDate() > 9 ? "" + date.getDate() : "0" + date.getDate()
    let monthKey: number = date.getMonth() + 1
    let month: string = monthKey > 9 ? "" + monthKey : "0" + monthKey
    return date.getFullYear() + "-" + month + "-" + day
  }

  function calcSelectedDate(offset: number): void {
    let newDate = new Date(Date.now() + offset * 24 * 60 * 60 * 1000)
    setSelectedDate(newDate)
  }

  let [selectedDate, setSelectedDate] = useState(new Date())
  let [sliderPos, setSliderPos] = useState(50)
  let [sliderMoved, setSliderMoved] = useState(false)
  let [voted, setVoted] = useState(false)
  let [cookies, setCookie] = useCookies()

  useEffect(() => {
    let fromCookie = cookies[formatDateTechnical(new Date())]
    if(fromCookie !== undefined) {
      setVoted(true)
      setSliderPos(fromCookie)
      calcSelectedDate(fromCookie)
    }
  }, [])

  let lastDate: Date = new Date(2023, 11, 31)
  let diff = lastDate.getTime() - Date.now()
  let diffInDays = Math.ceil(diff / (24 * 60 * 60 * 1000))

  return (
    <Container style={{ marginTop: 30 }}>
      <Grid container>
        <Grid item style={{ flexGrow: 1 }}>
          <b>&gt; {formatDate(new Date())}</b>
        </Grid>
        <Grid item>
          <b>{formatDate(lastDate)} &lt;</b>
        </Grid>
      </Grid>
      <Slider
        disabled={voted}
        max={diffInDays}
        onChange={(evt, newValue) => {
          let newPos: number = Array.isArray(newValue) ? newValue[0] : newValue
          calcSelectedDate(newPos)
          setSliderPos(newPos as number)
          setSliderMoved(true)
        }}
        value={sliderPos}
        aria-labelledby="continuous-slider" />
      <br />
      <Container style={{ textAlign: "center" }}>
        <Typography variant={"h5"}>
          Your guess: {formatDate(selectedDate)}
        </Typography>
        Which is in {sliderPos} days
        <br /><br />
        <Button variant="contained"
          color="primary"
          endIcon={<Icon>send</Icon>}
          disabled={voted || !sliderMoved}
          onClick={() => {
            let finalDate = formatDateTechnical(selectedDate)
            console.log(finalDate)
            setVoted(true)
            setCookie(formatDateTechnical(new Date()), sliderPos)
            let url = process.env.REACT_APP_API_ENDPOINT
            let payload = {
              'endDate': finalDate
            }
            fetch(url+'/prod', {
              method: 'POST',
              body: JSON.stringify(payload)
            }).catch((e) => console.log(e))
          }}>
          Submit
        </Button>
        {voted &&
          <Container>
            <i>Thanks for your guess! You can vote again tomorrow - if you like!</i>
          </Container>
        }
        {!sliderMoved && !voted &&
          <Container>
            <i>Set your guess with the slider</i>
          </Container>
        }
      </Container>
    </Container>
  )
}

export default Abstimmung;