import { Container, Typography } from '@material-ui/core'
import logo from './virus.png';

function Frage(props: any) {
  return (
    <Container>
      <img src={logo} className="App-logo" alt="logo"
        width={80}
        style={{ position: "absolute", zIndex: -1 }}
      />
      <Typography variant="h3" gutterBottom style={{ textAlign: "center", paddingTop: 20 }}>
        When will Corona be over?
      </Typography>
    </Container>
  )
}

export default Frage