import React from 'react'
import './App.css'
import Frage from './Frage'
import Abstimmung from './Abstimmung'
import Vorbei from './Vorbei'
import Ergebnis from './Ergebnis'
import { makeStyles } from '@material-ui/core/styles'
import { CssBaseline, Container, Divider } from '@material-ui/core'
import gotl from './gotl.png'

let useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function App() {
  let classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container maxWidth={"md"} style={{ marginTop: 15 }}>
        <Frage />
        <Abstimmung />
        <Divider variant={"middle"} style={{ marginTop: 20, marginBottom: 20 }} />
        <Ergebnis />
        <Divider variant={"middle"} style={{ marginTop: 20, marginBottom: 20 }} />
        <Vorbei />
        <Divider variant={"middle"} style={{ marginTop: 20, marginBottom: 10 }} />

        &#169; Powered by <a href="https://www.gotl.tech/" target="_blank">
          <img src={gotl} alt="logo"
            height={20}
          />
          <b>GOTL</b>tech - Your Wishes will come true!
        </a>
        <br /><br />
      </Container>

    </div>
  );
}

export default App;
