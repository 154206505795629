import { Container, Typography } from '@material-ui/core'

function Vorbei(porps: any) {
  return (
    <Container>
      <Typography variant="h4" style={{ textAlign: "center"}}>
        Over? What does "over" even mean?
      </Typography>
      <br />
      The corona virus is never fully disappear.<br />
      "Over" in this context mean that the topic of corona is no longer present in the press.<br />
      For that we check multiple news outlets twice a day and calculate the share of news that contain the words "corona" or "covid".<br />
      The day that none of the news outlets mentions one of those words is when corona is finally over!
    </Container> 
  )
}

export default Vorbei