import { Container, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react';
import { LineChart, AreaChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Area } from 'recharts';

function Ergebnis(props: any) {

  const [findings, setFindings]: [Object[], any] = useState([])
  const [minFindings, setMinFindings]: [Number, any] = useState(0)
  const [minFindingDate, setMinFindingDate]: [String, any] = useState('')
  const [tips, setTips]: [Object[], any] = useState([])

  useEffect(() => {
    const url = process.env.REACT_APP_DATA_BUCKET_URL + '/findings.json'
    fetch(url)
      .then(response => response.json())
      .then(d => {
        setFindings(d['findings'])
        let a = d['findings'].reduce((prevValue: any, currentValue: any) => {
          if(currentValue['Total'] <= prevValue['Total']) {
            return currentValue
          }
          else {
            return prevValue
          }
        }, {'Total': 100})
        setMinFindings(a['Total'])
        setMinFindingDate(a['date'])
      })
      .catch((error) => {
        console.error('Error:', error);
      })
  }, [])

  useEffect(() => {
    const url = process.env.REACT_APP_DATA_BUCKET_URL + '/tips.json'
    fetch(url)
      .then(response => response.json())
      .then(d => setTips(d['tips']))
      .catch((error) => {
        console.error('Error:', error);
      })
  }, [])

  return (
    <Container>
      <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
        Results
      </Typography>
      <br />
      The share of corona related news over time is:
      <br /><br />
      { findings.length > 0 &&
        <ResponsiveContainer height={300}>
          <LineChart data={findings}>
            <CartesianGrid stroke="#ccc" />
            <Line type="monotone" dot={false} dataKey="Total" stroke="#302dbd" strokeWidth={5} />
            <XAxis dataKey="date" />
            <YAxis 
              label={{ value: "% Corona-related news", angle: -90, position: "insideBottomLeft" }} 
              domain={[0, 50]}
            />
          </LineChart>
        </ResponsiveContainer>
      }
      <br />
      Lowest value so far: { minFindings }% on { minFindingDate }.
      <br /><br />
      And this is what people believe how long it will take until corona will be over:
      <br /><br />
      { tips.length > 0 &&
        <ResponsiveContainer height={300}>
          <AreaChart data={tips}>
            <Area type="monotone" dataKey="offset" stroke="#f00" />

            <XAxis dataKey="date" />
            <YAxis label={{ value: "Remaining duration (Days)", angle: -90, position: "insideBottomLeft" }} />
          </AreaChart>
        </ResponsiveContainer>
      }
    </Container>
  )
}

export default Ergebnis